<template>
    <div>
        <Navbar page="Novo template de diagnóstico" link="/diagnosticos/gerenciar" nameLink="Gerenciar templates de diagnósticos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    
                    <div class="flex flex-col gap-6 px-5 pb-4">
                        <div class="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-6 col-span-12 items-center">
                            <section class="col-span-1 md:col-span-12 lg:col-span-6">
                                <label for="tema" class="block text-sm font-medium">Tema</label>
                                <input 
                                    type="text" 
                                    v-model="form.tema" 
                                    name="tema" 
                                    id="tema" 
                                    autocomplete="off"  
                                    class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                >
                            </section> 

                            <div class="flex gap-1 col-span-1 md:col-span-12 lg:col-span-6">
                                <div>
                                    <svg 
                                        class="h-10 w-10 fill-gray-500"
                                        version="1.0" xmlns="http://www.w3.org/2000/svg"
                                        width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                        preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    stroke="none">
                                    <path d="M2504 5019 c-17 -19 -19 -41 -22 -183 -4 -184 6 -230 52 -242 45 -11
                                    76 4 92 43 12 29 15 70 12 199 -3 186 -10 204 -78 204 -25 0 -43 -7 -56 -21z"/>
                                    <path d="M1480 4758 c-28 -31 -25 -61 16 -142 77 -150 145 -257 172 -267 60
                                    -23 115 38 93 103 -16 44 -148 277 -174 306 -27 29 -79 30 -107 0z"/>
                                    <path d="M3533 4758 c-27 -29 -160 -263 -174 -306 -22 -66 33 -126 93 -103 39
                                    15 208 309 208 363 0 61 -85 91 -127 46z"/>
                                    <path d="M2388 4359 c-93 -13 -120 -23 -142 -52 -24 -33 -15 -70 24 -98 24
                                    -17 30 -17 125 -3 122 19 332 14 450 -11 257 -54 484 -177 669 -361 175 -175
                                    285 -368 354 -624 24 -88 26 -111 26 -305 0 -204 -1 -214 -32 -328 -36 -137
                                    -115 -309 -194 -424 -29 -43 -111 -136 -181 -208 -177 -179 -251 -305 -275
                                    -466 l-7 -54 -183 -3 -182 -2 0 345 c0 332 -1 346 -20 365 -30 30 -83 27 -109
                                    -6 -20 -26 -21 -38 -21 -365 l0 -339 -105 0 -105 0 0 585 0 585 104 0 105 0 3
                                    -146 c3 -139 4 -147 27 -165 30 -25 62 -24 95 2 26 20 26 21 26 164 l0 143 99
                                    4 c88 3 104 7 147 34 134 82 172 251 84 373 -124 171 -390 131 -458 -68 -7
                                    -19 -12 -69 -12 -112 l0 -79 -109 0 -108 0 -6 89 c-11 179 -102 274 -262 275
                                    -151 1 -255 -104 -255 -257 0 -112 66 -205 170 -242 24 -8 77 -15 121 -15 l79
                                    0 0 -585 0 -585 -178 0 -179 0 -17 83 c-33 153 -119 302 -243 417 -84 79 -168
                                    175 -219 251 -355 534 -279 1235 182 1680 106 102 188 161 309 223 50 26 96
                                    54 103 63 32 44 -3 113 -59 113 -34 0 -142 -52 -244 -118 -328 -211 -563 -561
                                    -642 -959 -22 -111 -24 -402 -4 -513 60 -328 212 -604 474 -856 133 -129 193
                                    -248 206 -411 8 -95 25 -123 78 -123 l33 0 0 -90 0 -90 -59 0 c-52 0 -62 -3
                                    -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0 -80 0 -80 -59 0
                                    c-52 0 -62 -3 -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0
                                    -32 c0 -45 40 -126 81 -167 39 -38 102 -70 139 -71 34 0 41 -7 69 -62 34 -67
                                    113 -143 190 -181 62 -30 72 -32 176 -32 103 0 114 2 177 32 80 38 152 106
                                    194 183 27 50 33 55 73 62 112 19 211 128 211 233 l0 35 54 0 c68 0 106 26
                                    106 74 0 54 -20 70 -92 76 l-63 5 -3 78 -3 77 54 0 c69 0 107 26 107 74 0 54
                                    -20 70 -92 76 l-63 5 0 85 0 85 38 6 c48 8 66 32 67 89 1 75 28 181 66 259 30
                                    61 62 101 178 221 241 248 362 463 428 755 25 115 36 388 19 507 -67 468 -355
                                    878 -772 1098 -161 84 -321 136 -492 159 -90 13 -305 13 -389 0z m-124 -1411
                                    c41 -18 59 -56 64 -135 l5 -73 -78 0 c-74 0 -78 1 -111 34 -27 27 -34 42 -34
                                    74 0 82 80 134 154 100z m752 -7 c42 -26 60 -78 44 -127 -18 -52 -59 -74 -142
                                    -74 l-68 0 0 74 c0 60 4 78 21 100 38 49 93 58 145 27z m74 -1761 l0 -90 -505
                                    0 -505 0 0 90 0 90 505 0 505 0 0 -90z m0 -320 l0 -80 -505 0 -505 0 0 80 0
                                    80 505 0 505 0 0 -80z m0 -251 c0 -27 -27 -72 -54 -90 -17 -11 -95 -15 -411
                                    -17 -214 -2 -409 0 -433 3 -53 8 -100 48 -108 93 l-6 32 506 0 506 0 0 -21z
                                    m-295 -283 c-18 -27 -77 -70 -118 -85 -87 -33 -218 0 -286 73 l-35 36 227 0
                                    227 0 -15 -24z"/>
                                    <path d="M755 3975 c-32 -31 -33 -75 -2 -102 45 -41 288 -173 318 -173 59 0
                                    91 73 52 118 -26 29 -295 182 -322 182 -12 0 -33 -11 -46 -25z"/>
                                    <path d="M4150 3915 c-79 -46 -150 -92 -157 -101 -19 -26 -16 -71 7 -94 35
                                    -35 71 -25 212 56 157 90 178 107 178 146 0 35 -40 78 -72 78 -13 0 -88 -38
                                    -168 -85z"/>
                                    <path d="M490 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0 187
                                    0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205 -20z"/>
                                    <path d="M4220 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0
                                    187 0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205
                                    -20z"/>
                                    <path d="M934 2028 c-78 -46 -149 -89 -158 -97 -22 -19 -21 -83 2 -104 35 -32
                                    75 -20 214 59 142 82 178 111 178 148 0 33 -29 65 -65 71 -25 5 -55 -9 -171
                                    -77z"/>
                                    <path d="M3990 2097 c-13 -7 -29 -25 -35 -40 -17 -49 9 -75 160 -164 150 -87
                                    196 -100 228 -65 22 25 22 85 0 104 -29 25 -298 178 -314 178 -8 0 -25 -6 -39
                                    -13z"/>
                                    </g>
                                    </svg>
                                </div>
                                <div class="text-gray-500 flex flex-col text-sm text-justify">
                                    <span class="font-bold">
                                    Orientação rápida
                                    </span>
                                    <span>
                                        Ao criar ou editar um questionário de diagnóstico, você pode optar por integrá-lo em um Plano
                                        de Ação, que na Fácil chamamos de PAI (Plano de Ação Integral). Ao selecionar a opção
                                        "considerar para o Plano de Ação Integral", você poderá associar uma ou mais
                                        vulnerabilidades e um ou mais controles (ações de mitigação) a cada pergunta. Para mais
                                        detalhes, assista ao nosso tutorial clicando no ícone no canto superior direito da tela inicial do
                                        módulo.
                                    </span>
                                </div>
                                </div>
                        </div>
                       
                        <section class="col-span-1 md:col-span-12 lg:col-span-6 flex items-center gap-2">
                            <input type="checkbox" v-model="form.incluirPAI" class="rounded-sm cursor-pointer" id="incluirPAI">
                            <label for="incluirPAI" class="text-sm font-medium cursor-pointer">
                                Considerar para Plano de Ação Integral - momento 1 (PAI -M1)
                            </label>
                        </section>

                        <section class="flex flex-wrap gap-2 items-center">
                            <button type="button" @click="addPergunta" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                Adicionar pergunta
                            </button>
                            <button type="button" @click="$modal.show('importarModal')" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg> 
                                Importar perguntas 
                            </button>
                            <a v-if="$route.params.id && form.perguntas.length > 0" :href="`${url_api}/v1/diagnosticosBase/download/${$route.params.id}?token=${$store.state.token}`" type="button" role="button" target= "_blank" rel="noreferrer" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg> 
                                Download perguntas 
                            </a>
                            <button type="button" @click="removerPerguntas" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                                Remover todas as perguntas
                            </button>

                            <div class="flex items-center gap-2">
                                <input type="checkbox" v-model="form.pesosManual" class="rounded-sm cursor-pointer" id="pesosManual">
                                <label for="pesosManual" class="text-sm font-medium cursor-pointer">
                                    Atribuir pesos às questões, manualmente
                                </label>
                            </div>
                        </section>
                    </div>

                    <hr class="pb-4 mx-5"/>
                   
                    <div v-for="(item, idx) in form.perguntas" :key="idx" class="flex flex-wrap lg:flex-nowrap gap-4 my-1 md:my-3 ">
                        <div class="">
                            <section class="flex items-center gap-4">
                                <label class="block text-sm font-bold whitespace-nowrap">
                                    Questão: {{ idx+1 }}
                                </label>
                                <button type="button" @click="remove(idx)" class="text-white text-sm hover:bg-red-600 bg-red-500 rounded-full h-9 w-9 flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 " fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </button>
                            </section>
                            <section v-if="form.pesosManual" class="">
                                <label class="block text-sm font-medium my-2">Peso: </label>
                                <div class="flex ">
                                    <button type="button" @click="changePeso(item,'adicionar')" class="bg-green-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </button>
                                    <div class="mx-2">
                                        <input v-model="item.peso" type="number" class="w-14 h-8 text-base font-medium text-center border-2">
                                    </div>
                                    <button type="button" @click="changePeso(item,'diminuir')" class="bg-red-600 text-white px-3 h-8 text-base font-medium text-center border-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                        </svg>
                                    </button>
                                </div>
                            </section>
                        </div>
                        <div class="grid grid-cols-12 gap-2 items-end w-full">
                            <div class="col-span-12 md:col-span-6 lg:col-span-4 ">
                                <label for="pergunta" class="block text-sm font-medium">Atribuir planos de ação Corrigir ortografia</label>
                                <textarea type="text" rows="3" v-model="item.pergunta" name="pergunta" id="pergunta" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>
                            <div class="col-span-12 md:col-span-6 lg:col-span-4 " v-if="form.incluirPAI">
                                <label for="vulnerabilidade" class="block text-sm font-medium">
                                    <b>Vulnerabilidades</b>
                                    existente atrelada a questão
                                    <svg fill="#059669" viewBox="0 0 256 256" @click="abrirModalVulnerabilidadePAI(idx)" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600 cursor-pointer">
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect 
                                    v-model="item.vulnerabilidades" 
                                    :options="vulnerabilidades"
                                    :multiple="true"
                                    :close-on-select="false"
                                    label="nome"
                                    trackBy="_id"
                                    @input="onSelectVulnerabilidadePAI($event, idx)" 
                                    placeholder="selecione as vulnerabilidades"
                                    selectedLabel="selecionado"
                                    deselectLabel="Pressione Enter para remover"
                                    selectLabel="Pressione Enter para selecionar"
                                    id="vulnerabilidade"
                                    class="mt-2 h-full focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                />
                            </div>
                            <div class="col-span-12 md:col-span-6 lg:col-span-4 " v-if="form.incluirPAI">
                                <label for="respostaNao" class="block text-sm font-medium">
                                    <b>Controles</b>
                                    aplicáveis em razão da vulnerabilidade existente
                                    <svg fill="#059669" viewBox="0 0 256 256" @click="abrirModalCondutaPAI(idx)" xmlns="http://www.w3.org/2000/svg" class="mx-1 h-6 inline text-green-600 cursor-pointer">
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </label>
                                <multiselect 
                                    v-model="item.respostaNao" 
                                    :options="condutasMitigacao"
                                    :multiple="true"
                                    :close-on-select="false"
                                    label="nome"
                                    trackBy="_id"
                                    placeholder="selecione as condutas"
                                    selectedLabel="selecionado"
                                    deselectLabel="Pressione Enter para remover"
                                    selectLabel="Pressione Enter para selecionar"
                                    id="respostaNao"
                                    class="mt-2 h-full focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                                />
                            </div>
                        </div>
                    </div>
                  
                    <div class="flex flex-wrap justify-end gap-4">
                        <div class="py-3 text-left">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="py-3 text-right">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
            <modal name="importarModal" :adaptive="true" :height="'auto'">
                <div class="px-5 py-5">
                    <h2 class="text-xl mb-2 font-bold"> Importar base</h2>
                    <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <a target= "_blank" rel="noreferrer" :href="`/diagnostico-modelo-perguntas.xlsx`" class="text-sm underline">Exemplo arquivo modelo importação</a>
                    </div>
                    <div class="col-span-12">
                        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
                        </vue-dropzone>
                    </div>
                    </div>
                </div>
            </modal>
            <modal name="modalVulnerabilidadesPAI" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova Vulnerabilidade </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="nome" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItem.nome"
                            type="text"
                            name="novaVulnerabilidadePAI"
                            id="novaVulnerabilidadePAI"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>

                <div class="grid grid-cols-2 mt-3">
                <div class="py-3 text-left">
                    <button @click="$modal.hide('modalVulnerabilidadesPAI')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="adicionarVulnerabilidadePAI" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
            </div>
        </modal>
        <modal name="modalCondutaPAI" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova conduta </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="nome" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItem.nome"
                            type="text"
                            name="novaCondutaPAI"
                            id="novaCondutaPAI"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>
             
                <div class="grid grid-cols-2 mt-3">
                    <div class="py-3 text-left">
                        <button @click="$modal.hide('modalCondutaPAI')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="py-3 text-right">
                        <button @click="adicionarCondutaPAI" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
        vueDropzone
    },
    data() {
        return {
            route: 'diagnosticos',
            idx: 0,
            opcoesVulnerabilidades: [],
            novoItem: {
                nome:'',
                index: null,
            },
            form: {
                tema: '',
                incluirPAI: false,
                pesosManual: false,
                perguntas: [],
            },
            formNovo: {
                descricao: '',
                opcao: '',
                ref: null,
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/diagnosticosBase/importar-csv`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
                dictRemoveFile: 'Remover'
            })
        }
    },
    methods: {
        abrirModalVulnerabilidadePAI(idx) {
            this.novoItem.index = idx;
            this.$modal.show('modalVulnerabilidadesPAI');
        },
        abrirModalCondutaPAI(idx) {
            this.novoItem.index = idx;
            this.$modal.show('modalCondutaPAI');
        },
        async adicionarVulnerabilidadePAI() {
            const index = this.novoItem.index

            const req = await this.$http.post(`/v1/vulnerabilidades`, this.novoItem);
            if (req.data.success) {
                this.$vToastify.success("Vulnerabilidade adicionada com sucesso!");
                this.$modal.hide('modalVulnerabilidadesPAI');

                this.vulnerabilidades.push(req.data.data);
                this.form.perguntas[index].vulnerabilidades.push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A vulnerabilidade já existe`
                return this.$vToastify.error(error);
            }

            this.novoItem.index = null;
            this.novoItem.nome = '';
        },
        async adicionarCondutaPAI() {

            const index = this.novoItem.index

            const req = await this.$http.post(`/v1/condutasmitigacao`, this.novoItem);
            if (req.data.success) {
                this.$vToastify.success("Conduta adicionada com sucesso!");
                this.$modal.hide('modalCondutaPAI');

                this.condutasMitigacao.push(req.data.data);
                this.form.perguntas[index].push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A conduta já existe`
                return this.$vToastify.error(error);
            }

            this.novoItem.index = null;
            this.novoItem.nome = '';
        },
        onSelectVulnerabilidadePAI (itens, index) {
            itens?.forEach(item => {
                for (let i = 0; i < item.condutas?.length; i++){
                    const c = item.condutas[i];

                    if (!this.form.perguntas[index].respostaNao?.some(cdt => cdt?.nome === c?.nome)) {
                        this.form.perguntas[index].respostaNao.push(c);
                    }
                }
            });
        },
        adicionarRespostaNao(idx, novoValor) {
            const respostaNao = this.form.perguntas[idx].respostaNao;
            if (!respostaNao.includes(novoValor)) respostaNao.push(novoValor); 
        },
        // adicionarRespostaParcial(idx, novoValor) {
        //     const respostaParcial = this.form.perguntas[idx].respostaParcial;
        //     if (!respostaParcial.includes(novoValor)) respostaParcial.push(novoValor); 
        // },
        addPergunta() {
            this.form.perguntas.push( {
                pergunta: "",
                peso: 1,
                vulnerabilidades: [], 
                respostaNao: [],
                respostaParcial: [],
            });
            window.scrollTo(0, document.body.scrollHeight);
        },
        async save() {
            if(!this.form.tema) return this.$vToastify.error("Digite o tema");
            if(!this.form.perguntas.length) return this.$vToastify.warning('Nenhuma pergunta adicionada.');
            const metodo = this.form._id ? 'put' : 'post'; 
            
            await this.$http[metodo](`/v1/diagnosticosBase/`, this.form)
            .then((resp)=>this.$vToastify.success(resp.data.msg || ''))
            .catch((e)=>this.$vToastify.error(e?.response?.data?.err || ''))
    
            if(metodo === 'post') this.$router.back();
        },
        async remove(idx){
            this.form.perguntas.splice(idx, 1); 
        },
        async removerPerguntas(){
            this.$confirm({
            title: 'Confirmação',
            message: `Tem certeza que deseja remover todas as perguntas do diagnóstico?`,
            button: {
                no: 'Não',
                yes: 'Sim',
            },
            callback: async confirm => { if (confirm) this.form.perguntas = [] }
        });
        },
        changePeso(p, change) {
            if(change === 'diminuir' && p.peso > 0) p.peso--;
            if(change === 'adicionar') p.peso++;
        },
        send(file, xhr, formData) {
            formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
            formData.append('idx', this.form.perguntas.length);
        },
        async update(file, response) {
            if(!response || !response.perguntas) return;
            this.form.perguntas.push(...response.perguntas);
            this.$modal.hide('importarModal');
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const vulnerabilidades = await this.$http.post(`/v1/vulnerabilidades/list`, { all: true});
        this.vulnerabilidades = vulnerabilidades.data.data;

        const condutasMitigacaoReq = await this.$http.post(`/v1/condutasmitigacao/list`,{ all: true});
        this.condutasMitigacao = condutasMitigacaoReq.data.data;

        if(id){
            await this.$http.get(`/v1/diagnosticosBase/${id}`)
            .then((resp)=> Object.assign(this.form, resp.data))
            .catch((e)=> this.$vToastify.error(e?.response?.data?.err || ''))
            
            await this.$http.get(`/v1/perguntaBase/diagnostico/${id}`)
            .then((resp)=> this.form.perguntas = resp.data)
            .catch((e)=> this.$vToastify.error(e?.response?.data?.err || ''))

        }
    },
}
</script>
